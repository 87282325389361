export const RdYlBu = [
  0.0,
  " #313695 ",
  0.00392156862745098,
  " #323896 ",
  0.00784313725490196,
  " #333b97 ",
  0.011764705882352941,
  " #333d99 ",
  0.01568627450980392,
  " #34409a ",
  0.0196078431372549,
  " #35429b ",
  0.023529411764705882,
  " #36459c ",
  0.027450980392156862,
  " #36479e ",
  0.03137254901960784,
  " #374a9f ",
  0.03529411764705882,
  " #384ca0 ",
  0.0392156862745098,
  " #394fa1 ",
  0.043137254901960784,
  " #3a51a2 ",
  0.047058823529411764,
  " #3a54a4 ",
  0.050980392156862744,
  " #3b56a5 ",
  0.054901960784313725,
  " #3c59a6 ",
  0.058823529411764705,
  " #3d5ba7 ",
  0.06274509803921569,
  " #3e5ea8 ",
  0.06666666666666667,
  " #3e60aa ",
  0.07058823529411765,
  " #3f62ab ",
  0.07450980392156863,
  " #4065ac ",
  0.0784313725490196,
  " #4167ad ",
  0.08235294117647059,
  " #416aaf ",
  0.08627450980392157,
  " #426cb0 ",
  0.09019607843137255,
  " #436fb1 ",
  0.09411764705882353,
  " #4471b2 ",
  0.09803921568627451,
  " #4574b3 ",
  0.10196078431372549,
  " #4676b5 ",
  0.10588235294117647,
  " #4878b6 ",
  0.10980392156862745,
  " #4a7ab7 ",
  0.11372549019607843,
  " #4b7db8 ",
  0.11764705882352941,
  " #4d7fb9 ",
  0.12156862745098039,
  " #4f81ba ",
  0.12549019607843137,
  " #5183bb ",
  0.12941176470588237,
  " #5385bd ",
  0.13333333333333333,
  " #5588be ",
  0.13725490196078433,
  " #578abf ",
  0.1411764705882353,
  " #588cc0 ",
  0.1450980392156863,
  " #5a8ec1 ",
  0.14901960784313725,
  " #5c90c2 ",
  0.15294117647058825,
  " #5e93c3 ",
  0.1568627450980392,
  " #6095c4 ",
  0.1607843137254902,
  " #6297c6 ",
  0.16470588235294117,
  " #6399c7 ",
  0.16862745098039217,
  " #659bc8 ",
  0.17254901960784313,
  " #679ec9 ",
  0.17647058823529413,
  " #69a0ca ",
  0.1803921568627451,
  " #6ba2cb ",
  0.1843137254901961,
  " #6da4cc ",
  0.18823529411764706,
  " #6ea6ce ",
  0.19215686274509805,
  " #70a9cf ",
  0.19607843137254902,
  " #72abd0 ",
  0.2,
  " #74add1 ",
  0.20392156862745098,
  " #76afd2 ",
  0.20784313725490197,
  " #78b0d3 ",
  0.21176470588235294,
  " #7ab2d4 ",
  0.21568627450980393,
  " #7db4d5 ",
  0.2196078431372549,
  " #7fb6d6 ",
  0.2235294117647059,
  " #81b7d7 ",
  0.22745098039215686,
  " #83b9d8 ",
  0.23137254901960785,
  " #85bbd9 ",
  0.23529411764705882,
  " #87bdd9 ",
  0.23921568627450981,
  " #8abeda ",
  0.24313725490196078,
  " #8cc0db ",
  0.24705882352941178,
  " #8ec2dc ",
  0.25098039215686274,
  " #90c3dd ",
  0.2549019607843137,
  " #92c5de ",
  0.25882352941176473,
  " #94c7df ",
  0.2627450980392157,
  " #97c9e0 ",
  0.26666666666666666,
  " #99cae1 ",
  0.27058823529411763,
  " #9bcce2 ",
  0.27450980392156865,
  " #9dcee3 ",
  0.2784313725490196,
  " #9fd0e4 ",
  0.2823529411764706,
  " #a1d1e5 ",
  0.28627450980392155,
  " #a3d3e6 ",
  0.2901960784313726,
  " #a6d5e7 ",
  0.29411764705882354,
  " #a8d6e8 ",
  0.2980392156862745,
  " #aad8e9 ",
  0.30196078431372547,
  " #acdae9 ",
  0.3058823529411765,
  " #aedbea ",
  0.30980392156862746,
  " #b0dcea ",
  0.3137254901960784,
  " #b2ddeb ",
  0.3176470588235294,
  " #b4deec ",
  0.3215686274509804,
  " #b6dfec ",
  0.3254901960784314,
  " #b9e0ed ",
  0.32941176470588235,
  " #bbe1ed ",
  0.3333333333333333,
  " #bde2ee ",
  0.33725490196078434,
  " #bfe3ef ",
  0.3411764705882353,
  " #c1e4ef ",
  0.34509803921568627,
  " #c3e5f0 ",
  0.34901960784313724,
  " #c5e6f0 ",
  0.35294117647058826,
  " #c7e7f1 ",
  0.3568627450980392,
  " #c9e8f2 ",
  0.3607843137254902,
  " #cbe9f2 ",
  0.36470588235294116,
  " #cdeaf3 ",
  0.3686274509803922,
  " #cfebf3 ",
  0.37254901960784315,
  " #d1ecf4 ",
  0.3764705882352941,
  " #d4edf4 ",
  0.3803921568627451,
  " #d6eef5 ",
  0.3843137254901961,
  " #d8eff6 ",
  0.38823529411764707,
  " #daf0f6 ",
  0.39215686274509803,
  " #dcf1f7 ",
  0.396078431372549,
  " #def2f7 ",
  0.4,
  " #e0f3f8 ",
  0.403921568627451,
  " #e1f3f6 ",
  0.40784313725490196,
  " #e2f4f4 ",
  0.4117647058823529,
  " #e4f4f1 ",
  0.41568627450980394,
  " #e5f5ef ",
  0.4196078431372549,
  " #e6f5ed ",
  0.4235294117647059,
  " #e7f6eb ",
  0.42745098039215684,
  " #e9f6e8 ",
  0.43137254901960786,
  " #eaf7e6 ",
  0.43529411764705883,
  " #ebf7e4 ",
  0.4392156862745098,
  " #ecf8e2 ",
  0.44313725490196076,
  " #edf8df ",
  0.4470588235294118,
  " #eff9dd ",
  0.45098039215686275,
  " #f0f9db ",
  0.4549019607843137,
  " #f1fad9 ",
  0.4588235294117647,
  " #f2fad6 ",
  0.4627450980392157,
  " #f3fbd4 ",
  0.4666666666666667,
  " #f5fbd2 ",
  0.47058823529411764,
  " #f6fbd0 ",
  0.4745098039215686,
  " #f7fcce ",
  0.47843137254901963,
  " #f8fccb ",
  0.4823529411764706,
  " #fafdc9 ",
  0.48627450980392156,
  " #fbfdc7 ",
  0.49019607843137253,
  " #fcfec5 ",
  0.49411764705882355,
  " #fdfec2 ",
  0.4980392156862745,
  " #feffc0 ",
  0.5019607843137255,
  " #fffebe ",
  0.5058823529411764,
  " #fffdbc ",
  0.5098039215686274,
  " #fffcba ",
  0.5137254901960784,
  " #fffbb9 ",
  0.5176470588235295,
  " #fffab7 ",
  0.5215686274509804,
  " #fff8b5 ",
  0.5254901960784314,
  " #fff7b3 ",
  0.5294117647058824,
  " #fff6b1 ",
  0.5333333333333333,
  " #fff5af ",
  0.5372549019607843,
  " #fff3ad ",
  0.5411764705882353,
  " #fff2ac ",
  0.5450980392156862,
  " #fff1aa ",
  0.5490196078431373,
  " #fff0a8 ",
  0.5529411764705883,
  " #feefa6 ",
  0.5568627450980392,
  " #feeda4 ",
  0.5607843137254902,
  " #feeca2 ",
  0.5647058823529412,
  " #feeba1 ",
  0.5686274509803921,
  " #feea9f ",
  0.5725490196078431,
  " #fee99d ",
  0.5764705882352941,
  " #fee79b ",
  0.5803921568627451,
  " #fee699 ",
  0.5843137254901961,
  " #fee597 ",
  0.5882352941176471,
  " #fee496 ",
  0.592156862745098,
  " #fee294 ",
  0.596078431372549,
  " #fee192 ",
  0.6,
  " #fee090 ",
  0.6039215686274509,
  " #fede8e ",
  0.6078431372549019,
  " #fedc8c ",
  0.611764705882353,
  " #feda8a ",
  0.615686274509804,
  " #fed889 ",
  0.6196078431372549,
  " #fed687 ",
  0.6235294117647059,
  " #fed485 ",
  0.6274509803921569,
  " #fed283 ",
  0.6313725490196078,
  " #fed081 ",
  0.6352941176470588,
  " #fece7f ",
  0.6392156862745098,
  " #fecc7e ",
  0.6431372549019608,
  " #feca7c ",
  0.6470588235294118,
  " #fec87a ",
  0.6509803921568628,
  " #fdc778 ",
  0.6549019607843137,
  " #fdc576 ",
  0.6588235294117647,
  " #fdc374 ",
  0.6627450980392157,
  " #fdc173 ",
  0.6666666666666666,
  " #fdbf71 ",
  0.6705882352941176,
  " #fdbd6f ",
  0.6745098039215687,
  " #fdbb6d ",
  0.6784313725490196,
  " #fdb96b ",
  0.6823529411764706,
  " #fdb769 ",
  0.6862745098039216,
  " #fdb567 ",
  0.6901960784313725,
  " #fdb366 ",
  0.6941176470588235,
  " #fdb164 ",
  0.6980392156862745,
  " #fdaf62 ",
  0.7019607843137254,
  " #fdad60 ",
  0.7058823529411765,
  " #fcaa5f ",
  0.7098039215686275,
  " #fca85e ",
  0.7137254901960784,
  " #fca55d ",
  0.7176470588235294,
  " #fba35c ",
  0.7215686274509804,
  " #fba05b ",
  0.7254901960784313,
  " #fb9d59 ",
  0.7294117647058823,
  " #fa9b58 ",
  0.7333333333333333,
  " #fa9857 ",
  0.7372549019607844,
  " #fa9656 ",
  0.7411764705882353,
  " #f99355 ",
  0.7450980392156863,
  " #f99153 ",
  0.7490196078431373,
  " #f98e52 ",
  0.7529411764705882,
  " #f88c51 ",
  0.7568627450980392,
  " #f88950 ",
  0.7607843137254902,
  " #f8864f ",
  0.7647058823529411,
  " #f7844e ",
  0.7686274509803922,
  " #f7814c ",
  0.7725490196078432,
  " #f67f4b ",
  0.7764705882352941,
  " #f67c4a ",
  0.7803921568627451,
  " #f67a49 ",
  0.7843137254901961,
  " #f57748 ",
  0.788235294117647,
  " #f57547 ",
  0.792156862745098,
  " #f57245 ",
  0.796078431372549,
  " #f47044 ",
  0.8,
  " #f46d43 ",
  0.803921568627451,
  " #f36b42 ",
  0.807843137254902,
  " #f26841 ",
  0.8117647058823529,
  " #f16640 ",
  0.8156862745098039,
  " #ef633f ",
  0.8196078431372549,
  " #ee613e ",
  0.8235294117647058,
  " #ed5f3c ",
  0.8274509803921568,
  " #ec5c3b ",
  0.8313725490196079,
  " #eb5a3a ",
  0.8352941176470589,
  " #ea5739 ",
  0.8392156862745098,
  " #e95538 ",
  0.8431372549019608,
  " #e75337 ",
  0.8470588235294118,
  " #e65036 ",
  0.8509803921568627,
  " #e54e35 ",
  0.8549019607843137,
  " #e44c34 ",
  0.8588235294117647,
  " #e34933 ",
  0.8627450980392157,
  " #e24731 ",
  0.8666666666666667,
  " #e14430 ",
  0.8705882352941177,
  " #e0422f ",
  0.8745098039215686,
  " #de402e ",
  0.8784313725490196,
  " #dd3d2d ",
  0.8823529411764706,
  " #dc3b2c ",
  0.8862745098039215,
  " #db382b ",
  0.8901960784313725,
  " #da362a ",
  0.8941176470588236,
  " #d93429 ",
  0.8980392156862745,
  " #d83128 ",
  0.9019607843137255,
  " #d62f27 ",
  0.9058823529411765,
  " #d42d27 ",
  0.9098039215686274,
  " #d22b27 ",
  0.9137254901960784,
  " #d02927 ",
  0.9176470588235294,
  " #ce2827 ",
  0.9215686274509803,
  " #cc2627 ",
  0.9254901960784314,
  " #ca2427 ",
  0.9294117647058824,
  " #c82227 ",
  0.9333333333333333,
  " #c62027 ",
  0.9372549019607843,
  " #c41e27 ",
  0.9411764705882353,
  " #c21c27 ",
  0.9450980392156862,
  " #c01a27 ",
  0.9490196078431372,
  " #be1827 ",
  0.9529411764705882,
  " #bd1726 ",
  0.9568627450980393,
  " #bb1526 ",
  0.9607843137254902,
  " #b91326 ",
  0.9647058823529412,
  " #b71126 ",
  0.9686274509803922,
  " #b50f26 ",
  0.9725490196078431,
  " #b30d26 ",
  0.9764705882352941,
  " #b10b26 ",
  0.9803921568627451,
  " #af0926 ",
  0.984313725490196,
  " #ad0826 ",
  0.9882352941176471,
  " #ab0626 ",
  0.9921568627450981,
  " #a90426 ",
  0.996078431372549,
  " #a70226 ",
  1.0,
  " #a50026 ",
];
export const turbo = [
  0.0,
  " #30123b ",
  0.00392156862745098,
  " #321543 ",
  0.00784313725490196,
  " #33184a ",
  0.011764705882352941,
  " #341b51 ",
  0.01568627450980392,
  " #351e58 ",
  0.0196078431372549,
  " #36215f ",
  0.023529411764705882,
  " #372466 ",
  0.027450980392156862,
  " #38276d ",
  0.03137254901960784,
  " #392a73 ",
  0.03529411764705882,
  " #3a2d79 ",
  0.0392156862745098,
  " #3b2f80 ",
  0.043137254901960784,
  " #3c3286 ",
  0.047058823529411764,
  " #3d358b ",
  0.050980392156862744,
  " #3e3891 ",
  0.054901960784313725,
  " #3f3b97 ",
  0.058823529411764705,
  " #3f3e9c ",
  0.06274509803921569,
  " #4040a2 ",
  0.06666666666666667,
  " #4143a7 ",
  0.07058823529411765,
  " #4146ac ",
  0.07450980392156863,
  " #4249b1 ",
  0.0784313725490196,
  " #424bb5 ",
  0.08235294117647059,
  " #434eba ",
  0.08627450980392157,
  " #4451bf ",
  0.09019607843137255,
  " #4454c3 ",
  0.09411764705882353,
  " #4456c7 ",
  0.09803921568627451,
  " #4559cb ",
  0.10196078431372549,
  " #455ccf ",
  0.10588235294117647,
  " #455ed3 ",
  0.10980392156862745,
  " #4661d6 ",
  0.11372549019607843,
  " #4664da ",
  0.11764705882352941,
  " #4666dd ",
  0.12156862745098039,
  " #4669e0 ",
  0.12549019607843137,
  " #466be3 ",
  0.12941176470588237,
  " #476ee6 ",
  0.13333333333333333,
  " #4771e9 ",
  0.13725490196078433,
  " #4773eb ",
  0.1411764705882353,
  " #4776ee ",
  0.1450980392156863,
  " #4778f0 ",
  0.14901960784313725,
  " #477bf2 ",
  0.15294117647058825,
  " #467df4 ",
  0.1568627450980392,
  " #4680f6 ",
  0.1607843137254902,
  " #4682f8 ",
  0.16470588235294117,
  " #4685fa ",
  0.16862745098039217,
  " #4687fb ",
  0.17254901960784313,
  " #458afc ",
  0.17647058823529413,
  " #458cfd ",
  0.1803921568627451,
  " #448ffe ",
  0.1843137254901961,
  " #4391fe ",
  0.18823529411764706,
  " #4294ff ",
  0.19215686274509805,
  " #4196ff ",
  0.19607843137254902,
  " #4099ff ",
  0.2,
  " #3e9bfe ",
  0.20392156862745098,
  " #3d9efe ",
  0.20784313725490197,
  " #3ba0fd ",
  0.21176470588235294,
  " #3aa3fc ",
  0.21568627450980393,
  " #38a5fb ",
  0.2196078431372549,
  " #37a8fa ",
  0.2235294117647059,
  " #35abf8 ",
  0.22745098039215686,
  " #33adf7 ",
  0.23137254901960785,
  " #31aff5 ",
  0.23529411764705882,
  " #2fb2f4 ",
  0.23921568627450981,
  " #2eb4f2 ",
  0.24313725490196078,
  " #2cb7f0 ",
  0.24705882352941178,
  " #2ab9ee ",
  0.25098039215686274,
  " #28bceb ",
  0.2549019607843137,
  " #27bee9 ",
  0.25882352941176473,
  " #25c0e7 ",
  0.2627450980392157,
  " #23c3e4 ",
  0.26666666666666666,
  " #22c5e2 ",
  0.27058823529411763,
  " #20c7df ",
  0.27450980392156865,
  " #1fc9dd ",
  0.2784313725490196,
  " #1ecbda ",
  0.2823529411764706,
  " #1ccdd8 ",
  0.28627450980392155,
  " #1bd0d5 ",
  0.2901960784313726,
  " #1ad2d2 ",
  0.29411764705882354,
  " #1ad4d0 ",
  0.2980392156862745,
  " #19d5cd ",
  0.30196078431372547,
  " #18d7ca ",
  0.3058823529411765,
  " #18d9c8 ",
  0.30980392156862746,
  " #18dbc5 ",
  0.3137254901960784,
  " #18ddc2 ",
  0.3176470588235294,
  " #18dec0 ",
  0.3215686274509804,
  " #18e0bd ",
  0.3254901960784314,
  " #19e2bb ",
  0.32941176470588235,
  " #19e3b9 ",
  0.3333333333333333,
  " #1ae4b6 ",
  0.33725490196078434,
  " #1ce6b4 ",
  0.3411764705882353,
  " #1de7b2 ",
  0.34509803921568627,
  " #1fe9af ",
  0.34901960784313724,
  " #20eaac ",
  0.35294117647058826,
  " #22ebaa ",
  0.3568627450980392,
  " #25eca7 ",
  0.3607843137254902,
  " #27eea4 ",
  0.36470588235294116,
  " #2aefa1 ",
  0.3686274509803922,
  " #2cf09e ",
  0.37254901960784315,
  " #2ff19b ",
  0.3764705882352941,
  " #32f298 ",
  0.3803921568627451,
  " #35f394 ",
  0.3843137254901961,
  " #38f491 ",
  0.38823529411764707,
  " #3cf58e ",
  0.39215686274509803,
  " #3ff68a ",
  0.396078431372549,
  " #43f787 ",
  0.4,
  " #46f884 ",
  0.403921568627451,
  " #4af880 ",
  0.40784313725490196,
  " #4ef97d ",
  0.4117647058823529,
  " #52fa7a ",
  0.41568627450980394,
  " #55fa76 ",
  0.4196078431372549,
  " #59fb73 ",
  0.4235294117647059,
  " #5dfc6f ",
  0.42745098039215684,
  " #61fc6c ",
  0.43137254901960786,
  " #65fd69 ",
  0.43529411764705883,
  " #69fd66 ",
  0.4392156862745098,
  " #6dfe62 ",
  0.44313725490196076,
  " #71fe5f ",
  0.4470588235294118,
  " #75fe5c ",
  0.45098039215686275,
  " #79fe59 ",
  0.4549019607843137,
  " #7dff56 ",
  0.4588235294117647,
  " #80ff53 ",
  0.4627450980392157,
  " #84ff51 ",
  0.4666666666666667,
  " #88ff4e ",
  0.47058823529411764,
  " #8bff4b ",
  0.4745098039215686,
  " #8fff49 ",
  0.47843137254901963,
  " #92ff47 ",
  0.4823529411764706,
  " #96fe44 ",
  0.48627450980392156,
  " #99fe42 ",
  0.49019607843137253,
  " #9cfe40 ",
  0.49411764705882355,
  " #9ffd3f ",
  0.4980392156862745,
  " #a1fd3d ",
  0.5019607843137255,
  " #a4fc3c ",
  0.5058823529411764,
  " #a7fc3a ",
  0.5098039215686274,
  " #a9fb39 ",
  0.5137254901960784,
  " #acfb38 ",
  0.5176470588235295,
  " #affa37 ",
  0.5215686274509804,
  " #b1f936 ",
  0.5254901960784314,
  " #b4f836 ",
  0.5294117647058824,
  " #b7f735 ",
  0.5333333333333333,
  " #b9f635 ",
  0.5372549019607843,
  " #bcf534 ",
  0.5411764705882353,
  " #bef434 ",
  0.5450980392156862,
  " #c1f334 ",
  0.5490196078431373,
  " #c3f134 ",
  0.5529411764705883,
  " #c6f034 ",
  0.5568627450980392,
  " #c8ef34 ",
  0.5607843137254902,
  " #cbed34 ",
  0.5647058823529412,
  " #cdec34 ",
  0.5686274509803921,
  " #d0ea34 ",
  0.5725490196078431,
  " #d2e935 ",
  0.5764705882352941,
  " #d4e735 ",
  0.5803921568627451,
  " #d7e535 ",
  0.5843137254901961,
  " #d9e436 ",
  0.5882352941176471,
  " #dbe236 ",
  0.592156862745098,
  " #dde037 ",
  0.596078431372549,
  " #dfdf37 ",
  0.6,
  " #e1dd37 ",
  0.6039215686274509,
  " #e3db38 ",
  0.6078431372549019,
  " #e5d938 ",
  0.611764705882353,
  " #e7d739 ",
  0.615686274509804,
  " #e9d539 ",
  0.6196078431372549,
  " #ebd339 ",
  0.6235294117647059,
  " #ecd13a ",
  0.6274509803921569,
  " #eecf3a ",
  0.6313725490196078,
  " #efcd3a ",
  0.6352941176470588,
  " #f1cb3a ",
  0.6392156862745098,
  " #f2c93a ",
  0.6431372549019608,
  " #f4c73a ",
  0.6470588235294118,
  " #f5c53a ",
  0.6509803921568628,
  " #f6c33a ",
  0.6549019607843137,
  " #f7c13a ",
  0.6588235294117647,
  " #f8be39 ",
  0.6627450980392157,
  " #f9bc39 ",
  0.6666666666666666,
  " #faba39 ",
  0.6705882352941176,
  " #fbb838 ",
  0.6745098039215687,
  " #fbb637 ",
  0.6784313725490196,
  " #fcb336 ",
  0.6823529411764706,
  " #fcb136 ",
  0.6862745098039216,
  " #fdae35 ",
  0.6901960784313725,
  " #fdac34 ",
  0.6941176470588235,
  " #fea933 ",
  0.6980392156862745,
  " #fea732 ",
  0.7019607843137254,
  " #fea431 ",
  0.7058823529411765,
  " #fea130 ",
  0.7098039215686275,
  " #fe9e2f ",
  0.7137254901960784,
  " #fe9b2d ",
  0.7176470588235294,
  " #fe992c ",
  0.7215686274509804,
  " #fe962b ",
  0.7254901960784313,
  " #fe932a ",
  0.7294117647058823,
  " #fe9029 ",
  0.7333333333333333,
  " #fd8d27 ",
  0.7372549019607844,
  " #fd8a26 ",
  0.7411764705882353,
  " #fc8725 ",
  0.7450980392156863,
  " #fc8423 ",
  0.7490196078431373,
  " #fb8122 ",
  0.7529411764705882,
  " #fb7e21 ",
  0.7568627450980392,
  " #fa7b1f ",
  0.7607843137254902,
  " #f9781e ",
  0.7647058823529411,
  " #f9751d ",
  0.7686274509803922,
  " #f8721c ",
  0.7725490196078432,
  " #f76f1a ",
  0.7764705882352941,
  " #f66c19 ",
  0.7803921568627451,
  " #f56918 ",
  0.7843137254901961,
  " #f46617 ",
  0.788235294117647,
  " #f36315 ",
  0.792156862745098,
  " #f26014 ",
  0.796078431372549,
  " #f15d13 ",
  0.8,
  " #f05b12 ",
  0.803921568627451,
  " #ef5811 ",
  0.807843137254902,
  " #ed5510 ",
  0.8117647058823529,
  " #ec530f ",
  0.8156862745098039,
  " #eb500e ",
  0.8196078431372549,
  " #ea4e0d ",
  0.8235294117647058,
  " #e84b0c ",
  0.8274509803921568,
  " #e7490c ",
  0.8313725490196079,
  " #e5470b ",
  0.8352941176470589,
  " #e4450a ",
  0.8392156862745098,
  " #e2430a ",
  0.8431372549019608,
  " #e14109 ",
  0.8470588235294118,
  " #df3f08 ",
  0.8509803921568627,
  " #dd3d08 ",
  0.8549019607843137,
  " #dc3b07 ",
  0.8588235294117647,
  " #da3907 ",
  0.8627450980392157,
  " #d83706 ",
  0.8666666666666667,
  " #d63506 ",
  0.8705882352941177,
  " #d43305 ",
  0.8745098039215686,
  " #d23105 ",
  0.8784313725490196,
  " #d02f05 ",
  0.8823529411764706,
  " #ce2d04 ",
  0.8862745098039215,
  " #cc2b04 ",
  0.8901960784313725,
  " #ca2a04 ",
  0.8941176470588236,
  " #c82803 ",
  0.8980392156862745,
  " #c52603 ",
  0.9019607843137255,
  " #c32503 ",
  0.9058823529411765,
  " #c12302 ",
  0.9098039215686274,
  " #be2102 ",
  0.9137254901960784,
  " #bc2002 ",
  0.9176470588235294,
  " #b91e02 ",
  0.9215686274509803,
  " #b71d02 ",
  0.9254901960784314,
  " #b41b01 ",
  0.9294117647058824,
  " #b21a01 ",
  0.9333333333333333,
  " #af1801 ",
  0.9372549019607843,
  " #ac1701 ",
  0.9411764705882353,
  " #a91601 ",
  0.9450980392156862,
  " #a71401 ",
  0.9490196078431372,
  " #a41301 ",
  0.9529411764705882,
  " #a11201 ",
  0.9568627450980393,
  " #9e1001 ",
  0.9607843137254902,
  " #9b0f01 ",
  0.9647058823529412,
  " #980e01 ",
  0.9686274509803922,
  " #950d01 ",
  0.9725490196078431,
  " #920b01 ",
  0.9764705882352941,
  " #8e0a01 ",
  0.9803921568627451,
  " #8b0902 ",
  0.984313725490196,
  " #880802 ",
  0.9882352941176471,
  " #850702 ",
  0.9921568627450981,
  " #810602 ",
  0.996078431372549,
  " #7e0502 ",
  1.0,
  " #7a0403 ",
];

export const grays = [0.0, " #F1F2F4 ", 0.5, " #CACFD5 ", 1.0, " #5F6774 "];
